@import url('https://fonts.googleapis.com/css2?family=Cinzel&family=Cinzel+Decorative&display=swap');

.app {
  min-height: 100vh;
  background-image: linear-gradient(180deg, #0d0e10, #212529 100px, #212529);

  .container {
    max-width: 960px;
  }

  header {
    .brand {
      text-shadow: -1px -1px 1px #262c2c, 1px 1px 1px #000;
      font-family: "Cinzel", serif;
    }

    nav {
      a {
        opacity: 0.8;
      }
      a:hover {
        opacity: 0.9;
      }
      a.active {
        opacity: 1;
        font-weight: bold;
      }
    }
  }

  footer {
    .brand {
      font-family: "Cinzel Decorative", cursive;
    }
  }
}
